<template>
    <!-- Login-->
    <p class="card-text mb-2 pt-2"><b> Continue with your Sabenzi journey!</b></p>
        <div v-if="!isAuthenticated">
        <!-- <h2 class="card-title fw-bold mb-1">Welcome to Sabenzi! 👋</h2> -->
        <!-- <p class="card-text mb-2 pt-2">Please sign-in to your account to continue the adventure</p> -->
        <form class="auth-login-form mt-2" action="#" @submit.prevent="createLogin">
            <div class="mb-1">
                <label class="form-label" :class="fv.email.msg?'text-danger':''" for="fv_email">
                    Email
                    <span v-if="fv.email.msg" > | {{fv.email.msg}}</span>
                </label>
                <input class="form-control" id="fv_email" type="email" required v-model.trim="fv.email.val"
                       @keyup="validate.validateFormData(fv,'email')" placeholder="Enter email" aria-describedby="login-email"
                        :class="fv.email.msg?'border-danger':''" autofocus="" tabindex="1" />
            </div>
            <div class="mb-1">
                <div class="d-flex justify-content-between">
                    <label class="form-label" for="login-password" :class="fv.password.msg?'text-danger':''">
                        Password
                        <span v-if="fv.password.msg" > | {{fv.password.msg}}</span>
                    </label>
                    <router-link to="/forgot_password" >
                        <small><i class="bi bi-lock"></i> Forgot Password?</small>
                    </router-link>

                </div>
                <div class="input-group input-group-merge form-password-toggle">
                    <input class="form-control form-control-merge" required id="fv_password"
                     :type="fv.password.showPassword?'text':'password'" v-model="fv.password.val"
                     :class="fv.password.msg?'border-danger':''"
                     placeholder="Enter Password" @keyup="validate.validateFormData(fv,'password')"
                     aria-describedby="login-password" min="8" max="32" tabindex="2" />
                     <span class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
                        <i class="bi bi-eye"></i>
                    </span>
                </div>
            </div>

            <div class="mt-1">
                <base-captcha :key="captcha" :captchaVal="captcha" ></base-captcha>
            </div>
            <div >
                <label v-if="fv.captcha.msg" class="form-label text-danger" >
                    {{fv.captcha.msg}}
                </label>
                <input class="form-control"  type="text"
                required v-model="fv.captcha.val"  min="7" max="7" @keyup="validate.validateFormData(fv,'captcha')"
                placeholder="Enter the text above" aria-describedby="captcha" autofocus="" tabindex="1" />
            </div>


            <div class="col-12" v-if="fv.reqFeedback.status">

                <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
            </div>

            <div >
                <base-button btnColor="primary" class="w-100 mt-2" tabindex="4" v-if="fv.showSubmit.val">
                    Login
                </base-button>
                <base-spinner  v-else></base-spinner>
            </div>

            <p class="text-center mt-2">
                <span>New on our platform?</span>
                <router-link to="/sign_up" >
                    <span>&nbsp; Create an account </span>
                </router-link>
            </p>
        </form>

        </div>

        <base-card-response class="mt-1" v-if="isAuthenticated">

            <template #header>Login was successful. </template>
            <template #default>
                <p>
                    Setting up your permissions.
                </p>
            </template>

        </base-card-response>
    <!-- End Login-->

</template>

<script>
import { ref,reactive,inject } from 'vue'
import { useStore } from 'vuex';
import validators from '@/hooks/validation'

export default{

  name: 'CreateAuthSignUp',
  setup() {
    // get the global vuex store objects
    const store = useStore();

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    // initiate token, csrf_token and captcha variables with blank values
    let token = "";
    let csrf_token = "";
    const captcha = ref("");

    const tokenData  = ref(false);
    const reqError  = ref("Loading login form....");



    // when the vue module is created, get the token value, using the try catch

            // use axios to get the data
            axios.get(coreUrl.backendUrl+'get_csrf_token',{withCredentials: true}).then((response) =>{
                // return the full token to the token variable
                token = response.data.data;
                tokenData.value = true;

                // make the first 43 characters, the csrf token
                csrf_token = token.substring(0,43)

                // make the last 5 characters, the captcha
                captcha.value = token.substring(43,48)


            }).catch((error)=>{
                // if an error occours, do this
                tokenData.value = false;
                const errorMsg = "Login form can't be loaded at this time. Try again";
                reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
            })




     // create variables for the values to be submitted
    const fv = reactive({
        email : {status:false, val:"", msg:false, validate:["required","email"]},
        password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        captcha : {status:false, val:"", msg:false, validate:["required","alphaNumeric"]},
        accountType : {status:false, val:"", msg:false, validate:["required"]},
        redirectLink : {status:false, val:"dashboard", msg:false, validate:["required"]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    // toggle password visibility
    function togglePasswordVisibility(){
        fv.password.showPassword = !fv.password.showPassword;
    }

    // get the current status of the created account
    const isAuthenticated = ref(store.getters["auth/getAuthAuthentication"].isAuthenticated);

    // create the account using async
    async function createLogin(){

        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();

        formData.append("fv_email", fv.email.val);
        formData.append("fv_password", fv.password.val);
        formData.append("fv_csrf_token",csrf_token+fv.captcha.val);
        formData.append("fv_account_type","organization");

        // post the data using axios
        try{

            const response = await axios.post(
                coreUrl.backendUrl+'create_basic_login',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data.data;



            if(response.data.status){
                console.log(response.data)
                fv.accountType.val = responseData.auth_credentials.accountType;


                // execute and update state for  token,authentication,credentials & permissions
                store.dispatch("auth/executeSetAuthToken", responseData.auth_token);
                store.dispatch("auth/executeSetAuthAuthentication", responseData.auth_authentication);
                store.dispatch("auth/executeSetAuthCredentials", responseData.auth_credentials);
                store.dispatch("auth/executeSetAuthPermissions", responseData.auth_permissions);

                // update is account created status
                isAuthenticated.value = true;
                fv.reqFeedback.status = false;

                fv.showSubmit.val = true;

                window.location.replace(coreUrl.tmsUrl+fv.redirectLink.val);
                // router.push(fv.redirectLink.val);
            }else{
                token =  response.data.data?response.data.data:"bad";
                csrf_token =  response.data.data?token.substring(0,43):"bad";
                captcha.value =  response.data.data?token.substring(43,48):"bad";

                // load the error to the appropriate field
                const errorMessages = response.data.message;

                for(const key in errorMessages.fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = errorMessages.fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = errorMessages.req_msg;
                fv.reqFeedback.val = "danger";
                fv.showSubmit.status = false;
                fv.showSubmit.val = true;
            }





        }catch(error){
            console.log(error)
            // redo the captcha sequence
            token =  error.response.data.data?error.response.data.data:"bad";
            csrf_token =  error.response.data.data?token.substring(0,43):"bad";
            captcha.value =  error.response.data.data?token.substring(43,48):"bad";

            // load the error to the appropriate field
            const errorMessages = error.response.data.message;

            for(const key in errorMessages){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = error.response.data.message[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = errorMessages["reqFeedback"]?errorMessages["reqFeedback"]:"Invalid email / password combination";
            fv.reqFeedback.val = "danger";
            fv.showSubmit.status = false;
            fv.showSubmit.val = true;

            // end load the error to the appropriate field

        }

     }



    return {
        fv,
        validate,
        tokenData,
        reqError,
        togglePasswordVisibility,
        createLogin,
        captcha,
        isAuthenticated
     }
  }
}
</script>
